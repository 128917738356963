import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import texts from './texts.json';

export function About({ lang, page }) {
  const navigate = useNavigate();

  useEffect(() => {
    const pB0Element = document.querySelector('.App');
    const pBhomeElement = document.querySelector('.b--home--pop');
    const p0Element = document.querySelector('.p0');
    const pBElement = document.querySelector('.p_b');
    const pZElement = document.querySelector('.p_z');

    // Function to show the popup
    const showPopup = () => {
      if (pB0Element) {
        pB0Element.style.overflow = 'hidden'; // Add overflow hidden style
        pB0Element.style.height = '100vh'; // Add height 100vh style
      }
      if (pBhomeElement) {
        pBhomeElement.classList.add('b--home--pop'); // Add class to home element
      }
      if (p0Element) {
        p0Element.classList.remove('inactive'); // Show the popup
        p0Element.scrollTo(0, 0); // Scroll to the top of p0
        p0Element.style.visibility = 'visible'; // Add overflow hidden style
        p0Element.classList.add('active');
      }
      if (pZElement) {
        pZElement.classList.remove('inactive'); // Show the overlay or related element
        pZElement.classList.add('active'); // Show the overlay or related element
      }
      if (pBElement) {
        pBElement.classList.add('active'); // Activate the popup
      }
    };

    // Check if the page is "about" on mount
    if (page === 'about') {
      showPopup(); // Show the popup if the page is "about"
    }

    // Cleanup function to remove active classes on unmount
    return () => {
      if (pBElement) {
        pBElement.classList.remove('active');
        if (pB0Element) {
          pB0Element.style.overflow = ''; // Reset overflow style
          pB0Element.style.height = ''; // Reset height style
          p0Element.classList.remove('active');
        }
      }
      if (pBhomeElement) {
        pBhomeElement.classList.remove('b--home--pop'); // Remove class from home element
      }
      if (pZElement) {
        pZElement.classList.remove('active'); // Hide the overlay or related element
        pZElement.classList.add('inactive'); // Hide the overlay or related element
      }
    };
  }, [page]); // Runs on mount and when 'page' changes

  const handleClose = () => {
    const pB0Element = document.querySelector('.App');
    const pBhomeElement = document.querySelector('.b--home--pop');
    const pBElement = document.querySelector('.p_b');
    const pZElement = document.querySelector('.p_z');
    const p0Element = document.querySelector('.p0');

    if (pB0Element) {
      pB0Element.style.overflow = ''; // Reset overflow style
      pB0Element.style.height = 'auto'; // Reset height style
    }
    if (pBhomeElement) {
      pBhomeElement.classList.remove('b--home--pop'); // Remove class from home element
    }
    if (pBElement) {
      pBElement.classList.remove('active');
    }
    if (pZElement) {
      pZElement.classList.remove('active');
    }
    if (p0Element) {
      p0Element.classList.add('inactive');
      p0Element.classList.remove('active');
      p0Element.style.visibility = 'hidden';
    }
    if (pZElement) {
      pZElement.classList.add('inactive');
    }

    // Redirect after a timeout
    setTimeout(() => {
      navigate(lang === 'ru' ? '/ru' : '/');
    }, 300);
  };

  return (
    <>
      <div className="p0 inactive"> {/* Initially inactive, will be shown when page is "about" */}
        <div className="p_z" onClick={handleClose} />
        <div className="p_b">
          <div className="w _w--narrow">
            <div className="p_b_c">
              <div className="x_psdg0 x_psdg0--pop">
                <div>{texts[lang].psdg.h1}</div>
              </div>
              <div className="x_psdg_story x_psdg_story--full">

                <img src="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/6742880871956a670c244925_psdg111.jpg" loading="lazy" width={611} sizes="(max-width: 479px) 100vw, (max-width: 767px) 34vw, (max-width: 991px) 36vw, 34vw" srcSet="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/6742880871956a670c244925_psdg111-p-500.jpg 500w, https://cdn.prod.website-files.com/66da39761f404aa0641056e6/6742880871956a670c244925_psdg111-p-800.jpg 800w, https://cdn.prod.website-files.com/66da39761f404aa0641056e6/6742880871956a670c244925_psdg111-p-1080.jpg 1080w, https://cdn.prod.website-files.com/66da39761f404aa0641056e6/6742880871956a670c244925_psdg111.jpg 1222w" className="x_psdg_story_i_left" />

                <p>{texts[lang].psdg.p1}</p>
                <p>{texts[lang].psdg.p2}</p>

                <img src="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/674287c11afe4410afd0d27f_psdg_11.jpg" loading="lazy" width={536} sizes="(max-width: 479px) 100vw, (max-width: 767px) 25vw, (max-width: 991px) 26vw, 25vw" srcSet="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/674287c11afe4410afd0d27f_psdg_11-p-500.jpg 500w, https://cdn.prod.website-files.com/66da39761f404aa0641056e6/674287c11afe4410afd0d27f_psdg_11-p-800.jpg 800w, https://cdn.prod.website-files.com/66da39761f404aa0641056e6/674287c11afe4410afd0d27f_psdg_11.jpg 1072w" className="x_psdg_story_i_right" />

                <p>{texts[lang].psdg.p3}</p>
                <p>{texts[lang].psdg.p4}</p>
                <p>{texts[lang].psdg.p5}</p>

                <img src="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/674289f106a920f998586865_psdg2222.jpg" loading="lazy" width={581} sizes="(max-width: 479px) 100vw, (max-width: 767px) 34vw, (max-width: 991px) 36vw, 34vw" srcSet="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/674289f106a920f998586865_psdg2222-p-500.jpg 500w, https://cdn.prod.website-files.com/66da39761f404aa0641056e6/674289f106a920f998586865_psdg2222-p-800.jpg 800w, https://cdn.prod.website-files.com/66da39761f404aa0641056e6/674289f106a920f998586865_psdg2222.jpg 1062w" className="x_psdg_story_i_left" />

                <p>{texts[lang].psdg.p6}</p>
                <p>{texts[lang].psdg.p7}</p>
                <p>{texts[lang].psdg.p8}</p>
              </div>
              <div className="_fcc">
                <div className="x_button x_button--xl" onClick={handleClose}>
                  {texts[lang].ui.close}
                </div>
              </div>

            </div>
          </div>
          <div className="p_b_x0">
            <div className="x_button x_button--xl" onClick={handleClose}>
              <div className="t_icon">close</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
