import React, { Fragment, useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import { Context } from '../context';
import { LayoutMain } from './LayoutMain';
import { Videos } from './Videos';
import SyncDataButton from './SyncDataButton';
import { Photos } from './Photos';
import { Books } from './Books';


export function RoutesMain(props) {

  const context = useContext(Context);


  return (
    <div>

      <Router>
        <Routes>



          <Route
            exact path="/"
            element={<>
              <LayoutMain page="home" />
            </>
            }
          />

          <Route
            exact path="/photos"
            element={<>
              <LayoutMain page="photos">
                <Photos />
              </LayoutMain>

            </>
            }
          />
          <Route
            exact path="/books"
            element={<>
              <LayoutMain page="books">
                <Books />
              </LayoutMain>

            </>
            }
          />

          <Route
            exact path="/en/about"
            element={<>
              <LayoutMain page="about" />
            </>
            }
          />

          <Route
            exact path="/ru"
            element={<>
              <LayoutMain lang="ru" />
            </>
            }
          />
          <Route
            exact path="/ru/about"
            element={<>
              <LayoutMain lang="ru" page="about" />
            </>
            }
          />

          <Route
            exact path="/fetch"
            element={<>
              <Videos />
            </>
            }
          />

          <Route
            exact path="/sync"
            element={<>
              <SyncDataButton />
            </>
            }
          />



        </Routes>


      </Router>
      {/* <img src="https://app.netlify.com/access-control/bb-api/api/v1/badges/612caafd-3ea7-4dff-a293-51d319dc56d1/deploy-status" alt="Deploy status badge" height={20}
        style={{ position: 'fixed', bottom: 0, right: 0, height: 8 }}
      /> */}

    </div>
  )
}