import React, { useContext } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { Context } from '../context';
import { PhotoCard } from './PhotoCard';
import Masonry from 'react-masonry-css';
import { Card } from 'antd';
import { Txt } from './UiKit/Txt';

export function Books(props) {
  const {
    size = 'l',
    category,
  } = { ...props };

  const [searchParams] = useSearchParams();
  const context = useContext(Context);

  const filterCat = (arr) => {
    if (!category) {
      return arr;
    } else {
      return arr.filter(one => one.categories.includes(category))
        .sort((a, b) => b.order - a.order);
    }
  };

  const filteredBooks = filterCat(context.books);

  return (
    <>
      <div className="w _w--100">
        <div className="x_v">
          <br />
          <br />
          {Boolean(filteredBooks.length) && (
            <div className="x_v_c">
              <Masonry
                breakpointCols={{ default: 6, 768: 3, 479: 2 }}
                className="my-masonry-grid my-masonry-grid--books"
                columnClassName="my-masonry-grid_column my-masonry-grid_column--books"
              >
                {filteredBooks.map(oneBook => (
                  <BookCard
                    size={size}
                    key={oneBook._id}
                    {...oneBook}
                  />
                ))}
              </Masonry>
            </div>
          )}
        </div>
      </div>
    </>
  );
}






export function BookCard(book) {
  const {
    _id,
    id,
    title,
    title_sub,
    order,
    link,
    description,
    image: {
      thumbnails: { large }
    }
  } = { ...book }

  return (
    <>
      <div className="_rel _tl my-masonry-grid_item my-masonry-grid_item--books _fv _fgap08"
        key={_id}
      >

        {/* <div className="x_hover" /> */}


        <img className="my-masonry-grid_item_img" src={large.url} alt={id} style={{ width: large.widtht }} />

        <h3 style={{ marginBottom: 0, marginTop: 0 }}>{title}</h3>
        <h4 style={{ marginBottom: 0, marginTop: 0 }}>{title_sub}</h4>
        <Txt>{description}</Txt>

        <div className="_f _fm _f100" >
          <div className="x_button disabled" >
            Buy digital
          </div> Soon
        </div>


      </div>

    </>
  )
}