import React, { useEffect, useState } from 'react'
import gallery from './static/gallery.json';
const Context = React.createContext();
var Airtable = require('airtable');


function Provider({ children }) {

  const AIRTABLE_URL = process.env.REACT_APP_AIRTABLE_URL;
  const AIRTABLE_APIKEY = process.env.REACT_APP_AIRTABLE_APIKEY;
  var base = new Airtable({ apiKey: AIRTABLE_APIKEY }).base(AIRTABLE_URL);


  const [ready, setReady] = useState(false)
  const [language, setLanguage] = useState('en')

  const [view, setView] = useState('g')

  const [search, setSearch] = useState('')
  const [filterVenue, setFilterVenue] = useState('')
  const [filterYear, setFilterYear] = useState('')


  const [videos, setVideos] = useState([])
  const [photos, setPhotos] = useState([])
  const [books, setBooks] = useState([])
  const [venues, setVenues] = useState([])
  const [videoCategories, setVideoCategories] = useState([])


  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const categoriesResponse = await fetch('/data/videoCategories.json');
  //       const venuesResponse = await fetch('/data/venues.json');
  //       const videosResponse = await fetch('/data/videos.json');

  //       if (!categoriesResponse.ok || !venuesResponse.ok || !videosResponse.ok) {
  //         throw new Error('Failed to fetch data');
  //       }

  //       const categoriesData = await categoriesResponse.json();
  //       const venuesData = await venuesResponse.json();
  //       const videosData = await videosResponse.json();

  //       setVideoCategories(categoriesData);
  //       setVenues(venuesData);
  //       setVideos(videosData);
  //       setReady(true); // Set ready to true after data is loaded
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);



  useEffect(() => {
    const fetchVideoCategories = async () => {
      try {
        const records = await base('videoCategories').select().all();
        const results = records
          .filter(one => one.get('active'))
          // .filter(one => one.get('videos'))
          .filter(one => one.get('videos') || one.get('id') === 'audio')
          .map(one => ({
            '_id': one.id,
            'id': one.get('id'),
            'order': one.get('order'),
            'title-en': one.get('title-en'),
            'title-ru': one.get('title-ru'),
            'videos': one.get('videos') ? one.get('videos') : [],
          }))
          .map(one => ({
            ...one,
            'title': one[`title-${language}`],
          }))
          .sort((a, b) => b.order - a.order)

        console.log('cats = ', results);
        setVideoCategories(results)
      } catch (error) {
        console.error('Error fetching records:', error);
      }

    }
    if (Object.keys(videoCategories).length === 0) {
      fetchVideoCategories();
    }
  }, []);


  const fetchVenues = async (language) => {
    try {
      const records = await base('venues').select().all();
      const results = records
        .filter(one => one.get('active'))
        .filter(one => one.get('videos').length)
        .map(one => ({
          '_id': one.id,
          'id': one.get('id'),
          'title': one.get('title'),
          'sub_title': one.get('sub_title'),
          'videos': one.get('videos') ? one.get('videos') : [],
          'videoLanguages': one.get('videoLanguages') ? one.get('videoLanguages').filter(one => one === language) : [],
        }))
        .sort((a, b) => b.videos.length - a.videos.length)
        .filter(one => one.videoLanguages.length)
      setVenues(results)
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  }

  useEffect(() => {

    if (language) {
      fetchVenues(language);
    }
  }, [language]);



  const fetchVideos = async (language) => {
    try {
      const records = await base('videos').select().all();
      const results = records
        .filter(one => one.get('active'))
        .filter(one => one.get('youtubeId'))
        .map(one => ({
          '_id': one.id,
          'id': one.get('id'),
          'order': one.get('order'),
          'youtubeId': one.get('youtubeId') ? one.get('youtubeId') : '',
          'language': one.get('language') ? one.get('language') : ['en', 'ru'],
          'title': one.get('id'),
          'start': one.get('start') ? one.get('start') : 0,
          'startTime': one.get('start_time') ? one.get('start_time') : 0,
          'endTime': one.get('end_time') ? one.get('end_time') : null,
          'categories': one.get('categories') ? one.get('categories') : [],
          'venue_id': one.get('venue_id') ? one.get('venue_id') : [],
          'venue_title': one.get('venue_title') ? one.get('venue_title')[0] : '',
          'venue_sub_title': one.get('venue_sub_title') ? one.get('venue_sub_title')[0] : '',
          'date': one.get('date') ? one.get('date') : '',
        }))
        .filter(one => one.language.includes(language))
        .sort((a, b) => b.order - a.order)

      // console.log('results = ', results);
      if (typeof results === 'object') setVideos(results)
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  };


  // const fetchPhotos = async () => {
  //   try {
  //     const records = await base('photos').select().all();
  //     const results = records
  //       .filter(one => one.get('active'))
  //       .filter(one => one.get('image'))
  //       .map(one => ({
  //         '_id': one.id,
  //         'id': one.get('id'),
  //         'order': one.get('order'),
  //         'image': one.get('image') ? one.get('image')[0] : {},
  //       }))
  //       .sort((a, b) => b.order - a.order)

  //     console.log('results = ', results);
  //     if (typeof results === 'object') setPhotos(results)
  //   } catch (error) {
  //     console.error('Error fetching records:', error);
  //   }
  // };

  // const fetchPhotos = async () => {
  //   try {
  //     // Fetch photos from Cloudinary with the updated cloud name
  //     const response = await fetch('https://api.cloudinary.com/v1_1/psdg/resources/image'); // Updated cloud name
  //     const records = await response.json(); // Assuming the response is in JSON format

  //     const results = records.resources
  //       .filter(one => one.active) // Adjust this based on your Cloudinary data structure
  //       .map(one => ({
  //         '_id': one.public_id,
  //         'id': one.public_id,
  //         'order': one.created_at,
  //         'image': one.secure_url ? one.secure_url : {},
  //       }))
  //       .sort((a, b) => b.order - a.order);

  //     console.log('results = ', results);
  //     if (Array.isArray(results)) setPhotos(results);
  //   } catch (error) {
  //     console.error('Error fetching records:', error);
  //   }
  // };


  const fetchPhotos = () => {
    try {
      // Map over the resources array to create an array of photo objects
      const photos = gallery.map(resource => ({
        _id: resource.asset_id, // Using public_id as _id
        id: resource.public_id,   // Using public_id as id
        image: resource.url, // Using secure_url for image
        width: resource.width,
        height: resource.height,
        display_name: resource.display_name,
      }));

      // Set the photos in your state (assuming you have a state setter)
      setPhotos(photos); // Assuming setPhotos is a state setter for your photos
    } catch (error) {
      console.error('Error processing photos:', error);
    }
  };

  const fetchBooks = async () => {
    try {
      const records = await base('books').select().all();
      const results = records
        .filter(one => one.get('active'))
        // .filter(one => one.get('link'))
        .map(one => ({
          '_id': one.id,
          'id': one.get('id'),
          'order': one.get('order'),
          'title-en': one.get('title-en'),
          'title-ru': one.get('title-ru'),
          'title_sub-en': one.get('title_sub-en'),
          'title_sub-ru': one.get('title_sub-ru'),
          'link-en': one.get('link-en') ? one.get('link-en') : '',
          'link-ru': one.get('link-ru') ? one.get('link-ru') : '',
          'image-en': one.get('image-en') ? one.get('image-en')[0] : {},
          'image-ru': one.get('image-ru') ? one.get('image-ru')[0] : {},
          'description-en': one.get('description-en') ? one.get('description-en') : '',
          'description-ru': one.get('description-ru') ? one.get('description-ru') : '',
        }))
        .map(one => ({
          ...one,
          'title': one[`title-${language}`],
          'title_sub': one[`title_sub-${language}`],
          'link': one[`link-${language}`],
          'image': one[`image-${language}`],
          'description': one[`description-${language}`],
        }))
        .sort((a, b) => b.order - a.order)

      console.log('results = ', results);
      if (typeof results === 'object') setBooks(results)
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  };


  useEffect(() => {

    if (language) {
      fetchVideos(language);
      fetchPhotos();
      fetchBooks();
    }



  }, [language]);




  const getVideoById = (_id) => {
    let vid = videos.filter(one => one._id === _id)[0];
    if (vid) {
      vid['title'] = vid[`title-${language}`];
    } else {
      vid = {}
    }
    return vid;
  }

  const getVenueById = (id) => {
    let ven = venues.filter(one => one.id === id)[0];
    if (ven) {
      ven = ven;
    } else {
      ven = {}
    }
    return ven;
  }


  const filterLang = (arr) => {
    return arr.filter(one => one.language.includes(language))
  }

  const filterContent = (arr, { fv = filterVenue, fy = filterYear, s = search } = {}) => {
    let a = arr
    if (fv) {
      a = a.filter(one => one.venue_id.includes(fv))
        .sort((a, b) => b.order - a.order)
    }
    if (fy) {
      a = a.filter(one => one.date.split('-')[0] === fy)
        .sort((a, b) => b.order - a.order)
    }
    if (s) {
      a = a.filter(one => {
        let a = one.title.toLowerCase().search(typeof s === 'string'
          ? s.toLowerCase()
          : '')
        return a > -1
      })
        .sort((a, b) => b.order - a.order)
    }

    return a
  }



  return (<>
    <Context.Provider
      value={{
        state: {
          language,
          ready,
          filterVenue,
          filterYear,
          search,
          view,
        },
        videoCategories,
        videos,
        photos,
        books,
        venues,
        x: {
          filterContent,
          filterLang,
          getVideoById,
          getVenueById,
          setPhotos,
          setBooks,
          setLanguage,
          setFilterVenue,
          setFilterYear,
          setSearch,
          setView,
        },
      }}
    >
      {children}
    </Context.Provider>
  </>
  )

}

const Consumer = Context.Consumer;

export { Context, Provider, Consumer };
