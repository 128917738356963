import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Context } from '../context';
import { PhotoCard } from './PhotoCard';
import Masonry from 'react-masonry-css';

export function Photos(props) {
  const {
    size = 'l',
    category,
  } = { ...props };

  const [searchParams] = useSearchParams();
  const context = useContext(Context);

  const filteredPhotos = context.photos;

  return (
    <>
      <div className="w _w--100">
        <div className="x_v">
          <br />
          {Boolean(filteredPhotos.length) && (
            <div className="x_v_c">
              <Masonry
                breakpointCols={{ default: 4, 768: 3, 479: 2 }}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {filteredPhotos.map(photo => (
                  <PhotoCard
                    key={photo._id}
                    photo={photo}
                  />
                ))}
              </Masonry>
            </div>
          )}
        </div>
      </div>
    </>
  );
}