import React from 'react';

export function PhotoCard({ photo }) {
  const {
    _id,
    id,
    image,
    width,
    height,
  } = photo;

  const aspectRatio = width / height;

  return (
    <div style={{ width: '100%', aspectRatio: aspectRatio, position: 'relative' }}>
      <img
        className="my-masonry-grid_item"
        key={_id}
        src={image}
        alt={id}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        onLoad={() => {
          // Trigger a re-layout if necessary
          // This can be a function to force a re-render or layout recalculation
        }}
      />
    </div>
  );
}