import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { Context } from '../context';
import { VideoCard } from './VideoCard';
import { Breadcrumb, Button, Layout, Menu, theme } from 'antd';
import { Icon } from './UiKit/Icon';

export function VideoCat(props) {
  const {
    children,
    size = 'l',
    title = '',
    category,
    order,
  } = { ...props };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();

  const context = { ...useContext(Context) };


  const filterCat = (arr) => {
    if (!category) {
      return arr
    } else {
      return arr.filter(one => one.categories.includes(category))
        .sort((a, b) => b.order - a.order)
    }
  }



  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const getVenueTitle = (id) => {
    return context.venues.find(one => one.id === id)?.title
  }


  return (
    <>

      <div className="w _w--100">

        <div className="x_v">






          {Boolean(context.x.filterLang(
            context.x.filterContent(
              filterCat(context.videos)
            )
          ).length) && <div className="x_v_c">

              <div className="x_videos_section" id={category}>


              <div className="x_videos_h0">
                <div className="_f _fm">
                <h2 className='x_videos_h' style={{ width: '100%', margin: 0 }}>{title}</h2>
                  {context.state.filterVenue && <div className="x_button active" onClick={() => context.x.setFilterVenue()}>
                    <div className="ellipsis" style={{ maxWidth: '10em' }}>
                      {getVenueTitle(context.state.filterVenue)}
                    </div>
                    <div className="_ico">
                      clear
                    </div>
                  </div>}
                  {context.state.filterYear && <div className="x_button active" onClick={() => context.x.setFilterYear()}>
                    {context.state.filterYear}
                    <div className="_ico">
                      clear
                    </div>
                  </div>}
                </div>
                {/* <div className="_f _fr _f100 _fgap02 mobile_h_no">
                  <div
                    className={`x_button ${context.state.view === 'g' ? 'active' : ''}`}
                    onClick={() => context.x.setView('g')}
                  ><Icon>grid_view</Icon></div>
                  <div
                    className={`x_button ${context.state.view === 'table' ? 'active' : ''}`}
                    onClick={() => context.x.setView('table')}
                  ><Icon>view_agenda</Icon>
                  </div>
                </div> */}
              </div>


              {/* <div className="_f">
                  <Button
                    type={context.state.language === 'en' ? 'primary' : 'secondary'}
                    onClick={() => context.x.setLanguage('en')}
                  >
                    En
                  </Button>
                  <Button type={context.state.language === 'ru' ? 'primary' : 'secondary'}
                    onClick={() => context.x.setLanguage('ru')}
                  >
                    Ru
                  </Button>
                </div> */}




              {/* <div className={`${context.state.view === 'g' ? 'x_videos_grid' : 'x_videos_table'}`}> */}
              <div className={`x_videos_grid`}>

                  {/* <div className={`x_videos_bg x_videos_bg--${order}`} /> */}

                {context.x.filterLang(
                  context.x.filterContent(
                    filterCat(context.videos)
                  )
                )
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map(oneVideo => {
                    return (<VideoCard
                      size={size}
                      view={context.state.view}
                      key={oneVideo._id}
                      {...oneVideo}
                    />)
                  })
                }

                {!context.x.filterLang(
                  context.x.filterContent(
                    filterCat(context.videos)
                  )
                ).length &&
                  <div className="_f100 _fv _fcc" style={{ minHeight: '10em' }}>
                    No videos with this filters
                  </div>
                }

                {(context.state.filterVenue || context.state.filterYear)
                  && <div className='_fcc _f100' style={{ marginTop: '2em' }} ><div style={{ fontSize: '1.4em' }} className="x_button active"
                    onClick={() => {
                      context.x.setFilterVenue()
                      context.x.setFilterYear()
                    }}>
                    <div>
                      Clear filters
                    </div>
                  </div></div>}


              </div>


              </div>
            </div>

          }


        </div>
      </div>
    </>
  )
}