import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Breadcrumb, Button, Layout, Menu, theme } from 'antd';
import './add0.css';
import '../psdg.css';
import './add.css';
import { VideoCat } from './VideoCat';
import { Context } from '../context';
import { VideoPop } from '../VideoPop';
import { Filters } from './Filters';
import { Hero } from './Hero';
import { Footer } from '../Footer';
import { Preloader } from './UiKit/Preloader';
import { Top } from './Top';
import { About } from './About';
import { Audios } from './Audios';

const items = new Array(15).fill(null).map((_, index) => ({
  key: index + 1,
  label: `nav ${index + 1}`,
}));




export const LayoutMain = ({ lang = 'en', page = '', children }) => {

  const context = { ...useContext(Context) };


  useEffect(() => {
    if (lang === 'ru') {
      context.x.setLanguage('ru');
    } else {
      context.x.setLanguage('en');
    }
  }, [lang]);


  const filters = useRef(null);

  useEffect(() => {
    // Add scroll event listener


    // Set initial styles for animation
    const animateElements = () => {
      const elements = [
        filters,
      ];
      elements.forEach((ref, index) => {
        if (ref.current) {
          ref.current.style.opacity = '0';
          ref.current.style.transform = 'translateY(-20px)';
          ref.current.style.transition = 'opacity 0.5s ease, transform 0.5s ease'; // Add transition
        }
      });

      // Trigger animation after a staggered delay
      elements.forEach((ref, index) => {
        setTimeout(() => {
          if (ref.current) {
            ref.current.style.opacity = '1';
            ref.current.style.transform = 'translateY(0)';
          }
        }, 100 + index + 5 * 100); // Stagger by 100ms for each element
      });
    };

    animateElements();

  }, []);




  return (<>

    <About lang={lang} page={page} />

    <VideoPop />




    <div ref={filters} >
    <Layout
      className="b b--home"
      style={{
        // background: 'var(--var-bg)',
        // paddingBottom: '4em'
      }}
    >



        <Top lang={lang} />
        <Hero page={page} />

        {children && <div className="cc " id="photos" >
          {children}
        </div>}


        {!children && <div className="cc " id="videos" >
          <div className="x_v_top">



            <Filters lang={lang} />



        </div>

          <div className="x_v">

            {!context.videos.length && <div className="_f100 _fv _fcc" style={{ minHeight: '10em' }}>
              <Preloader width={80} height={80} />
            </div>}

            {Boolean(context.videos.length) && context.videoCategories.map((one, index) => {
          return (<VideoCat
            lang={lang}
            key={one._id}
            order={index}
            title={one[`title-${context.state.language}`]}
            category={one.id}
          />)
            })}


            {/* <Audios /> */}

            {children}

          </div>


        </div>}

        <Footer lang={lang} />

    </Layout>
    </div>
  </>
  );
};